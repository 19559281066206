// React components
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

// app component
import App from "./App";

// CSS
// import "./index.css";
import "./dashboard-styles.css";
import "./download-tool-styles.css";
import "./search-tool-styles.css";
import "./world-map-styles.css";

// styles
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";

// theme
import theme from "./theme";

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <App />
      </StyledEngineProvider>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
