import { createTheme } from "@mui/material/styles";

const palette = {
  primary: "#698EC7",
  primaryHover: "rgb(45, 109, 216)",
  background: "rgb(0, 0, 0, 0.02)",
  backgroundHover: "rgb(0, 0, 0, 0.04)",
  backgroundCode: "rgb(0, 0, 0, 0.04)",
  line: "rgb(0, 0, 0, 0.08)",
  lineHover: "rgb(0, 0, 0, 0.16)",
  lineFocus: "rgb(55, 125, 247)",
  textLight: "rgb(0, 0, 0, 0.4)",
  textDark: "rgb(0, 0, 0, 0.8)",
  textCode: "rgb(51, 102, 160)",
};

const theme = createTheme({

  lineHeight: 1.8,
  appBarHeight: 75,
  appBarHeightMobile: 125,
  sideBarWidth: 275,

  breakpoints: {
    values: {
      mobile: 0,
      desktop: 768,
    },
  },
  palette: {
    custom: {
      primary: palette.primary,
      primaryHover: palette.primaryHover,
      background: palette.background,
      backgroundHover: palette.backgroundHover,
      backgroundCode: palette.backgroundCode,
      line: palette.line,
      lineHover: palette.lineHover,
      lineFocus: palette.lineFocus,
      textLight: palette.textLight,
      textDark: palette.textDark,
      textCode: palette.textCode,
    },
    primary: {
      main: palette.primary,
      dark: palette.primaryDark,
    },
    secondary: {
      main: palette.primary,
      dark: palette.primaryDark,
    },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
    button: {
      fontWeight: 400,
      textTransform: "none",
    },
    h1: {
      fontFamily: "Roboto Condensed",
      fontWeight: 600,
      fontSize: 50,
      // paddingTop: 16,
      paddingBottom: 10,
      color: "#FFFFFF",
      // textAlign: "center",
    },
    h2: {
      fontFamily: "Roboto Condensed",
      fontWeight: 400,
      fontSize: 18,
      paddingBottom: 10,
      color: "#FFFFFF",
    },
    h3: {
      fontFamily: "Roboto Condensed",
      fontWeight: 600,
      fontSize: 24,
      paddingBottom: 10,
      lineHeight: 1.5,
      color: palette.textDark,
    },
    h4: {
      fontFamily: "Roboto Condensed",
      fontWeight: 600,
      fontSize: 32,
      paddingBottom: 10,
      lineHeight: 1.5,
      color: palette.textDark,
    },
    h5: {
      fontFamily: "Roboto Condensed",
      fontWeight: 400,
      fontSize: 22,
      paddingTop: 28,
      paddingBottom: 10,
      lineHeight: 1.5,
      color: palette.textDark,
    },
    h6: {
      fontFamily: "Roboto Condensed",
      fontWeight: 600,
      fontSize: 20,
      paddingTop: 28,
      paddingBottom: 10,
      lineHeight: 1.5,
      color: palette.textDark,
    },
    // body1: {
    //   fontWeight: 300,
    //   fontSize: 16,
    //   lineHeight: 1.8,
    //   paddingBottom: 10,
    //   paddingTop: 10,
    //   color: palette.textLight,
    // },
    // body2: {
    //   fontWeight: 300,
    // },
    subtitle1: {
      fontWeight: 300,
      fontSize: 16,
      paddingBottom: 4,
      paddingTop: 4,
      lineHeight: 1.5
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiCheckbox: {
      size: "small",
    },
  },
  overrides: {
    MuiListItemIcon: {
      root: {
        minWidth: 0,
        paddingRight: 16,
      },
    },
    MuiCheckbox: {
      root: {
        color: palette.lineHover,
      },
    },
    MuiCard: {
      root: {
        borderRadius: 16,
        "-webkit-mask-image": "-webkit-radial-gradient(white, black)",
      },
    },
    MuiAppBar: {
      root: {
        height: 75,
        boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.1)",
      },
      colorPrimary: {
        // backgroundColor: "#FFFFFF",
      },
    },
    MuiToolbar: {
      root: {
        height: 75,
      },
    },
    MuiButton: {
      "& root": {
        borderRadius: 50,
      },
      contained: {
        borderRadius: 50,
        borderWidth: 2,
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 22,
        paddingRight: 22,
        minWidth: 70,
        boxShadow: "none",
        fontWeight: 400,
        "&:hover": {
          boxShadow: "none",
          backgroundColor: palette.primaryMain,
        },
        "&:focus": {
          boxShadow: "none",
          backgroundColor: palette.primaryMain,
        },
        "&:hover, &:focus": {
          boxShadow: "none",
          backgroundColor: palette.primaryMain,
        },
        "&:active": {
          boxShadow: "none",
          backgroundColor: palette.primaryMain,
        },
      },
      outlined: {
        borderRadius: 8,
      },
      outlinedPrimary: {
        borderColor: palette.primaryMain,
        "&:hover": {
          backgroundColor: "rgba(75, 148, 230, 0.2)",
        },
      },
    },
    MuiMenu: {
      paper: {
        borderRadius: 8,
        boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.1)",
      },
    },
    MuiList: {
      padding: {
        paddingTop: 12,
        paddingBottom: 12,
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(0, 0, 0, 0.2)",
      },
    },
    MuiDrawer:  {
      paper: {
        // boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.1)",
        // maxWidth: 300,
        // borderStyleRight: "solid",
        // borderWidth: 2,
        // borderColor: palette.line,
        // backgroundColor: palette.background,
      },
    },
    MuiTextField: {
      root: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: palette.line,
          borderWidth: 1,
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: palette.lineHover,
          borderWidth: 1,
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: palette.lineFocused,
          borderWidth: 1,
        },
        "& .MuiOutlinedInput-input": {
          color: palette.textDark,
          fontWeight: 300,
          // padding: 20,
        },
        "&:hover .MuiOutlinedInput-input": {
          color: palette.textDark,
          // padding: 20,
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
          color: palette.textDark,
          // padding: 20,
        },
        "& .MuiInputLabel-outlined": {
          color: palette.textDark,
          fontWeight: 300,
        },
        "&:hover .MuiInputLabel-outlined": {
          color: palette.textDark,
          fontWeight: 300,
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
          color: palette.textDark,
          fontWeight: 300,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 8,
      },
    },
    MuiListItem: {
      root: {
        minHeight: 46,
      },
    },
    MuiMenuItem: {
      dense: {
        minHeight: 46,
        fontSize: 14,
      },
    },
    MuiChip: {
      colorPrimary: {
        fontWeight: 300,
        marginTop: 8,
        marginBottom: 8,
        marginRight: 16,
      },
    },
  },
});

export default theme;
