
const bios = {
  fjelstul: {
    name: "Joshua Fjelstul",
    preview: "Joshua Fjelstul is a post-doctoral research fellow in the Department of Political Science and International Relations at the University of Geneva in Geneva and a researcher at the ARENA Centre for European Studies at the University of Oslo.",
    institution: "The University of Geneva",
    bio: "Joshua Fjelstul is a political scientist and data scientist. He has a Ph.D. in Political Science from Emory University (2019). He was previously a post-doctoral research fellow at Washington University in St. Louis. He studies international institutions and international political economy (IPE). His ressearch focuses on the politics of noncompliance with international law and European Union (EU) law, the institutional design and internal politics of international courts, including the Court of Justice of the European Union (CJEU), and the politics of international and European economic integration. His work uses advanced quantitative methods and game theory. His research has appeared in the American Political Science Review (APSR), the Journal of European Public Policy (JEPP), the Journal of Law and Courts (JLC), the Journal of Theoretical Politics (JTP), European Union Politics (EUP), and International Interactions (II).",
    photo: "/bio-photos/fjelstul.jpg",
    email: "mailto: joshua.fjelstul@unige.ch",
    website: "http://www.joshuafjelstul.com",
    twitter: "https://twitter.com/joshfjelstul",
    github: "http://www.github.com/jfjelstul",
  },
  hug: {
    name: "Simon Hug",
    preview: "Simon Hug is a professor of Political Science in the Department of Political Science and International Relations at the University of Geneva.",
    institution: "The University of Geneva",
    bio: "Insert bio here.",
    photo: "/bio-photos/hug.jpg",
    email: "mailto: Simon.Hug@unige.ch",
    website: "https://www.unige.ch/sciences-societe/speri/membres/professeures-et-professeurs/simon-hug/",
  },
  kilby: {
    name: "Christopher Kilby",
    preview: "Christopher Kilby is a professor of Economics in the Villanova School of Business at Villanova University. He studies the political economy of foreign aid.",
    institution: "Villanova University",
    bio: "Insert bio here.",
    photo: "/bio-photos/kilby.jpg",
    email: "mailto: christopher.kilby@villanova.edu",
    website: "https://www1.villanova.edu/university/business/faculty-and-research/faculty-by-department/biodetail.html?mail=christopher.kilby@villanova.edu&xsl=bio_long",
  },
};

export { bios };
