// React components
import React, { useEffect, useState } from "react";

// icons
import IntroductionIcon from '@mui/icons-material/Star';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import SearchIcon from '@mui/icons-material/Search';
import ContributorsIcon from '@mui/icons-material/Person';
import DocumentationIcon from '@mui/icons-material/Description';
import FAQsIcon from '@mui/icons-material/Chat';
import TutorialIcon from '@mui/icons-material/MenuBook';
import RPackageIcon from '@mui/icons-material/Inventory';
import APIIcon from '@mui/icons-material/Source';

// pages
import Dashboard from "./Dashboard";

// panels
import { IntroductionPanel, DownloadPanel, SearchPanel, TutorialPanel, DocumentationPanel, FAQsPanel, RPackagePanel, APIPanel, ContributorsPanel } from './Panels';

// API address
const APIURL = 'https://api.unvotes.org';
// const APIURL = 'http://localhost:4010';

export default function App (props) {

  const data = [
    {
      icon: IntroductionIcon,
      label: 'Database',
      panel: IntroductionPanel,
      url: '/database',
      protected: false,
    },
    {
      icon: DownloadIcon,
      label: 'Download',
      panel: DownloadPanel,
      url: '/download',
      protected: false,
    },
    // {
    //   icon: SearchIcon,
    //   label: 'Search',
    //   panel: SearchPanel,
    //   url: '/search',
    //   protected: false,
    // },
    {
      icon: TutorialIcon,
      label: 'Tutorial',
      panel: TutorialPanel,
      url: '/tutorial',
      protected: false,
    },
    {
      icon: DocumentationIcon,
      label: 'Documentation',
      panel: DocumentationPanel,
      url: '/documentation',
      protected: false,
    },
    {
      icon: FAQsIcon,
      label: 'FAQs',
      panel: FAQsPanel,
      url: '/faqs',
      protected: false,
    },
    // {
    //   icon: RPackageIcon,
    //   label: 'R package',
    //   panel: RPackagePanel,
    //   url: '/r-package',
    //   protected: false,
    // },
  ];

  const logo =
  <div  className='menu__logo-text'><span className='menu__logo-text--large'>unvotes</span>.org</div>

  const dashboard =
  <Dashboard
    APIURL={APIURL}
    data={data}
    logo={logo}
    drawerWidth={250}
    appBarHeight={70}
    sidebarOnTop={false}
  />

  return (
    <div>
      {dashboard}
    </div>
  );
};
