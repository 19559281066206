import Typography from "@mui/material/Typography";

import { parseText } from './Utilities';

export default function CodebookComponent (props) {

  var datasetsJson = require("./tables.json");
  var variablesJson = require("./variables.json");

  const makeVariableDescription = (variable, i) => {
    const variableDescription =
    <div key={i} className="codebook__variable-container">
      <div className="codebook__variable-body">
        <span className="codebook__variable-name">{variable.variable}</span>
        <span className="codebook__variable-type">{variable.type}</span>
        <span className="codebook__variable-description" dangerouslySetInnerHTML={{__html: parseText(variable.description)}}/>
      </div>
    </div>
    return variableDescription;
  }

  const makeDatasetDescription = (dataset, i) => {
    var variables = variablesJson.filter(function(item) {
      return item.dataset_id === i + 1;
    });
    const datasetDescription =
    <div>
      <div key={i} className="codebook__dataset-container">
        <div className="codebook__dataset-label">
          {dataset.label}
        </div>
        <span className="codebook__dataset-name">
          {dataset.dataset}
        </span>
        <div className="codebook__dataset-description">
          <div dangerouslySetInnerHTML={{__html: parseText(dataset.description)}}/>
        </div>
        <div className="codebook__variables-container">
          {variables.map((variable, i) => (
            makeVariableDescription(variable, i)
          ))}
        </div>
      </div>
      {i !== datasetsJson.length - 1 ? <div className="codebook__divider"/> : null}
    </div>
    return datasetDescription;
  };

  return(
    <div className="codebook__container">
      {datasetsJson.map((dataset, i) => (
        makeDatasetDescription(dataset, i)
      ))}
    </div>
  )
};
