import { Link } from 'react-router-dom';
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/CloudDownload";
import CopyIcon from "@mui/icons-material/ContentCopy";

export default function CitationSection (props) {

  const paperCitation = "Fjelstul, Joshua C., Simon Hug and Christopher Kilby. 2023. \"Decision-Making in the United Nations General Assembly: A Comprehensive Database of Resolutions, Decisions, and Votes.\" Villanova School of Business Department of Economics and Statistics Working Paper Series No. 56. URL: https://EconPapers.repec.org/RePEc:vil:papers:56.";
  const databaseCitation = "Fjelstul, Joshua C., Simon Hug, and Christopher Kilby. 2023. \"The United Nations General Assembly Decision-Making (UNGA-DM) Database.\" URL: https://www.unvotes.org.";

  return(
    <div>
      <div className="paragraph">
        If you use data from the UNGA-DM Database in a paper, please cite our working paper:
      </div>
      <div className="quote">
        {paperCitation}
      </div>
      <div className="form__row--left">
        <div className="form__element-container--left">
          <Button variant="outlined" startIcon={<CopyIcon/>} onClick={() => navigator.clipboard.writeText(paperCitation)}>
            Copy to clipboard
          </Button>
        </div>
        <div className="form__element-container--left">
          <Button variant="outlined" startIcon={<DownloadIcon/>} href="/files/UNGA-DM-Database-working-paper.bib" download="UNGA-DM-Database-working-paper.bib">
            Citation (BibTeX)
          </Button>
        </div>
      </div>
      <div className="spacer--16"/>
      <div className="paragraph">
        Please also cite the UNGA-DM Database:
      </div>
      <div className="quote">
        {databaseCitation}
      </div>
      <div className="form__row--left">
        <div className="form__element-container--left">
          <Button variant="outlined" startIcon={<CopyIcon/>} onClick={() => navigator.clipboard.writeText(databaseCitation)}>
            Copy to clipboard
          </Button>
        </div>
        <div className="form__element-container--left">
          <Button variant="outlined" startIcon={<DownloadIcon/>} href="/files/UNGA-DM-Database.bib" download="UNGA-DM-Database.bib">
            Citation (BibTeX)
          </Button>
        </div>
      </div>
    <div className="spacer--16"/>
      <div className="paragraph">
        If you use the data from the UNGA-DM Database in a paper, please <a className="link" href="mailto: joshfjelstul@gmail.com">let us know</a> so we can feature your paper on <span className="bold--primary">unvotes.org</span>.
      </div>
    </div>
  )
};
