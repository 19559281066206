import Axios from "axios";

function scroll (id, offset) {
  const element = document.getElementById(id);
  const y = element.getBoundingClientRect().top + window.pageYOffset + offset;
  window.scrollTo({top: y, behavior: 'smooth'});
};

function code (string) {
  return <span className="code">{string}</span>
};

function bold (string) {
  return <span className="bold">{string}</span>
};

function capitalize (string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function parseText (string) {
  var newString = string;
  newString = newString.replaceAll("{", "<span class='code'>");
  newString = newString.replaceAll("}", "</span>");
  return(newString);
}

function trackAction (user, type, value) {
  const url = "https://www.api.unvotes.org/track-action";
  const body = { "user": user,  "type": type, "value": value };
  Axios.post(url, body)
  .then(response => {

  })
  .catch(error => {
    console.log(error);
  });
};

export { scroll, code, bold, capitalize, parseText, trackAction };
