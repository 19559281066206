// react components
import React, { Component } from "react";
import { Link } from "react-router-dom";

// mui components
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardActionArea from "@mui/material/CardActionArea";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import SelectArrow from "@mui/icons-material/KeyboardArrowDownRounded";
import DownloadIcon from '@mui/icons-material/CloudDownload';
import MoreIcon from '@mui/icons-material/MoreHoriz';
import StartIcon from '@mui/icons-material/Start';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CancelIcon from "@mui/icons-material/Cancel";

// components
import CitationComponent from "./CitationComponent";
import { trackAction } from "./Utilities";

export default function DatabaseComponent (props) {

  const { user } = props;

  const [selectedDataset, setSelectedDataset] = React.useState("");
  const [version, setVersion] = React.useState("v-2023-1");
  const [dataDialogOpen, setDataDialogOpen] = React.useState(false);
  const [votesDialogOpen, setVotesDialogOpen] = React.useState(false);

  var datasetsJson = require("./datasets.json");

  var downloadButtons =
  <div className="form__row--left">
    <div className="form__element-container--left">
      <Button
        variant="contained"
        startIcon={<DownloadIcon/>}
        href={"/files/UNGA-DM-Database-" + version + "-CSV.zip"}
        download={"UNGA-DM-Database-" + version + "-CSV.zip"}
        onClick={() => {
          trackAction(user, "download_database", "csv");
        }}
      >
        Database (CSV)
      </Button>
    </div>
    <div className="form__element-container--left">
      <Button
        variant="contained"
        startIcon={<DownloadIcon/>}
        href={"/files/UNGA-DM-Database-" + version + "-RData.zip"}
        download={"UNGA-DM-Database-" + version + "-RData.zip"}
        onClick={() => {
          trackAction(user, "download_database", "rdata");
        }}
      >
        Database (RData)
      </Button>
    </div>
    <div className="form__element-container--left">
      <Button
        variant="contained"
        startIcon={<DownloadIcon/>}
        href={"/files/UNGA-DM-Database-" + version + ".db"}
        download={"UNGA-DM-Database-" + version + ".db"}
        onClick={() => {
          trackAction(user, "download_database", "sqlite");
        }}
      >
        Database (SQLite)
      </Button>
    </div>
  </div>

  const dataDialog =
    <Dialog
      open={dataDialogOpen}
      onClose={() => setDataDialogOpen(false)}
      PaperProps={{ style: { width: "60vw", height: "80vh" }}}
      BackdropProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.3)" }}}
    >
      <div className="dialog__header-container">
        Download the full relational database
      </div>
      <div className="dialog__content-container">
        <div className="paragraph">
          Please select the version of the UNGA-DM Database you'd like to use.
        </div>
        <div className="form__row--left">
          <div className="form__element-container--left">
            <FormControl fullWidth>
              <InputLabel>Version</InputLabel>
              <Select
                variant="filled"
                value={version}
                IconComponent={SelectArrow}
                style={{ width: 275 }}
                onChange={(event) => setVersion(event.target.value)}
              >
                <MenuItem value="v-2023-1">Version 2023.1</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="spacer--16"/>
        <CitationComponent/>
        <div className="spacer--16"/>
        {downloadButtons}
      </div>
      <div className="dialog__footer-container">
        <Button
          variant="outlined"
          startIcon={<CancelIcon/>}
          onClick={() => setDataDialogOpen(false)}
        >
          Close
        </Button>
      </div>
    </Dialog>;

    var votesDownloadButtons =
    <div className="form__row--left">
      <div className="form__element-container--left">
        <Button
          variant="contained"
          startIcon={<DownloadIcon/>}
          href={"/files/UNGA-DM-Database-" + version + "-vote-data-CSV.zip"}
          download={"UNGA-DM-Database-" + version + "-vote-data-CSV.zip"}
          onClick={() => {
            trackAction(user, "download_votes_table", "csv");
          }}
        >
          Votes (CSV)
        </Button>
      </div>
      <div className="form__element-container--left">
        <Button
          variant="contained"
          startIcon={<DownloadIcon/>}
          href={"/files/UNGA-DM-Database-" + version + "-vote-data-RData.zip"}
          download={"UNGA-DM-Database-" + version + "-vote-data-RData.zip"}
          onClick={() => {
            trackAction(user, "download_votes_table", "rdata");
          }}
        >
          Votes (RData)
        </Button>
      </div>
    </div>

    const votesDialog =
      <Dialog
        open={votesDialogOpen}
        onClose={() => setVotesDialogOpen(false)}
        PaperProps={{ style: { width: "60vw", height: "80vh" }}}
        BackdropProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.3)" }}}
      >
        <div className="dialog__header-container">
          Download country-level vote data
        </div>
        <div className="dialog__content-container">
          <div className="paragraph">
            Please select the version of the UNGA-DM Database you'd like to use.
          </div>
          <div className="form__row--left">
            <div className="form__element-container--left">
              <FormControl fullWidth>
                <InputLabel>Version</InputLabel>
                <Select
                  variant="filled"
                  value={version}
                  IconComponent={SelectArrow}
                  style={{ width: 275 }}
                  onChange={(event) => setVersion(event.target.value)}
                >
                  <MenuItem value="v-2023-1">Version 2023.1</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="spacer--16"/>
          <CitationComponent/>
          <div className="spacer--16"/>
          {votesDownloadButtons}
        </div>
        <div className="dialog__footer-container">
          <Button
            variant="outlined"
            startIcon={<CancelIcon/>}
            onClick={() => setVotesDialogOpen(false)}
          >
            Close
          </Button>
        </div>
      </Dialog>;

  return(
    <div className='panel__content'>
      <div className="block">
        <div className="title">
          Country-level vote data (most users)
        </div>
        <div className="paragraph">
          The UNGA-DM Database is a relational database with eight tables, but most users will just need country-level vote data. Here, you can download a table with country-level data for all recorded votes in the UNGA that already has relevant variables from other tables merged in. This pre-merged table will meet the needs of most users. You can also download the full-relational database or use our download tool to build a custom table (see below).
        </div>
        <div className="paragraph">
          Our research-ready country-level data is available in two formats. You can download a <span className="code">.zip</span> file that contains a table in a <span className="code">.csv</span> format, or you can download a <span className="code">.zip</span> file that contains a table in an <span className="code">.RData</span> format.
        </div>
        <div className="form__button-container--center">
          <Button
            variant="contained"
            onClick={() => setVotesDialogOpen(true)}
          >
            Choose version
          </Button>
        </div>
      </div>
      <div className="divider"/>
      <div className="block">
        <div className="title">
          Relational database (advanced users)
        </div>
        <div className="paragraph">
          The full UNGA-DM Database has eight tables: the <span className="code">member_states</span> table covers UN member states, the <span className="code">sessions</span> table covers sessions of the UNGA, the <span className="code">meeting_records</span> table covers meeting records for UNGA meetings, the <span className="code">draft_resolutions</span> table covers draft resolutions proposed in the UNGA that are the subject of decisions, the <span className="code">resolutions</span> table covers resolutions that are adopted by the UNGA, the <span className="code">decisions</span> table covers decisions taken by the UNGA regarding draft resolutions, amendments, motions, and separate votes, the <span className="code">outcomes</span> table covers the outcomes of decisions made by recorded vote or by non-recorded vote, and the <span className="code">votes</span> table votes covers country-level votes cast by UN member states in recorded votes.
        </div>
        <div className="paragraph">
          The UNGA-DM Database is available in three formats. You can download a <span className="code">.zip</span> file that includes the datasets in a <span className="code">.csv</span> format, you can download a <span className="code">.zip</span> file that includes the datasets in an <span className="code">.RData</span> format, or you can download the database as an <span className="code">SQLite</span> database, which you can then query using <span className="code">R</span> or other software.
        </div>
        <div className="form__button-container--center">
          <Button
            variant="contained"
            onClick={() => setDataDialogOpen(true)}
          >
            Choose version
          </Button>
        </div>
      </div>
      {dataDialog}
      {votesDialog}
    </div>
  )
};
